import { Component, OnInit, Input, ViewChild, AbstractType, Inject } from '@angular/core';
import { LineChartComponent } from '../../charts/lineCharts/lineCharts.component';
import { BarChartsComponent } from '../../charts/bar-charts/bar-charts.component';
import { SummaryService } from 'src/app/services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../data-models/constants.enum';
import { Tab } from 'src/app/data-models/tab.stats';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

const crrGraphSeriesMapping = {
  [Constants.ExternalSupplemental]: [11],
  [Constants.OptumChartReview]: [12],
  [Constants.OptumIOA]: [13],
  [Constants.Others]: [14]
}

@Component({
  selector: 'app-crr-summary',
  templateUrl: './crr-summary.component.html',
  styleUrls: ['./crr-summary.component.css']
})
export class CrrSummaryComponent implements OnInit {

  @Input()
  public data: any;
 
  @ViewChild(LineChartComponent, { static: false })
  public lineChart: LineChartComponent;

  @ViewChild(BarChartsComponent, { static: false })
  public barChart: BarChartsComponent;

  public graphSeriesId : number[];
  
  
  private externalSupplementalCnt: number;
  private optumIOACnt: number;
  private optumChartReviewCnt: number;
  private aggData:any;
  countData: any; 
  constructor(private summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService) { }

  ngOnInit() {
    const partySk = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName).value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const year_val = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod).value;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.sessionStorage.set(Constants.yAxisLabel, JSON.stringify("Chart Review Records"));
    this.sessionStorage.set(Constants.seriesName, JSON.stringify("External Supplemental"));
    this.graphSeriesId = [11];
    this.sessionStorage.set(Constants.graphSeriesId, this.graphSeriesId);
    this.sessionStorage.set(Constants.tabsSelectedIndex, 0);
   
    this.summaryService.getChartReviewRecordCount(partySk, isChildDataIncluded,year_val,startDate,endDate,0).subscribe((data:any) => {
      this.countData = data;  
    });
    this.data["frequencyChange"] = this.reloadGraph.bind(this);
  }

  isBarChartVissible(){
    return false;
    /* const freqType = this.sessionStorage.get(Constants.graphViewByType);
    return this.data.timePeriod === Constants.today || 
    (this.data.timePeriod === Constants.customDateRange && 
    ((freqType === Constants.monthly && this.data[Constants.isSingleMonthDateRange]) 
    || this.data.duration === 1)); */
  }

  reloadGraph(seriesName) {
     this.sessionStorage.set(Constants.seriesName, JSON.stringify(seriesName));
     this.graphSeriesId = crrGraphSeriesMapping[seriesName];
   if(Constants.ExternalSupplemental===seriesName){
        this.graphSeriesId = [11];
      }else if(Constants.OptumChartReview===seriesName){
        this.graphSeriesId = [12];
      }
      else if(Constants.OptumIOA===seriesName){
        this.graphSeriesId = [13];
      }
      // else if(Constants.Others===seriesName){
      //   this.graphSeriesId = [14];
      // }
     if(this.isBarChartVissible()){
        this.barChart.reloadGraph(this.graphSeriesId);
      }else{
        this.lineChart.reloadGraph(this.graphSeriesId);
      }

  }

}
