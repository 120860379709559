<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3">
        <div><span class="chart-title">Chart Review Record Errors</span>
            <span class="margin-left-15">
                <a href="#" (click)="exportData($event)">Export Data <uitk-icon-font icon="uimf-icon-export" hiddenText="export"></uitk-icon-font></a>
            </span>
        </div>
    </div>
</div>

<div class="row error-tab-stats-wrapper">
    <div class="col-md-6  error-tab-stats padding-left-3">
        <div class="count-title">Errors Prior to Linking</div>
        <div class="count-sub-title-wraper"> 
        <ng-container *ngFor="let item of agg?.totalErrorByTypeInfo">   
        <div class="count-value" *ngIf='item?.typeName=="PTL"'>{{item?.totalErrTypeCnt}}</div>
        </ng-container>
            <div class="count-label">Chart Review Records</div>
         </div>
         <div class="row padding-2 margin-top-5">
            <div class="col-md-8 code-txt bold">Top Error Codes</div>
            <div class="col-md-4 code-txt text-align-right bold">Qty</div>
        </div>
        <div class="border-top-title"></div>
        <ng-container *ngFor="let item of agg?.codeInfo">
            <div class="errors-table" *ngIf='item?.errType=="PTL"'>
                <div class="code-txt bold" >{{item?.errCode}}</div>
                <div class="code-txt">{{item?.errCodeDescription}}</div>
                <div class="code-txt text-align-right">{{item?.errCodeQuantity}}</div>
            </div>
        </ng-container>
        <div class="bar-graph-wraper">
            <div class="count-title">Chart Review Errors by Source</div>
            <div class="color-bar">
                <div class="color-1" [ngStyle]="{ 'width': 100*ColorBarES_Count/ColorBarTotal +'%'}"></div>
                <div class="color-2" [ngStyle]="{ 'width': 100*ColorBarCR_Count/ColorBarTotal +'%'}"></div>
                <div class="color-3" [ngStyle]="{ 'width': 100*ColorBarIOA_Count/ColorBarTotal +'%'}"></div>
                <div class="color-4" [ngStyle]="{ 'width': 100*ColorBarOTH_Count/ColorBarTotal +'%'}"></div>
            </div>
            <div class="row padding-2">
               <div class="col-md-8  color-desc-wrap">
                    <span class="indicator color-1"> </span>
                    <span class="code-txt">External Supplemental</span>
                </div>
                <div class="col-md-4 code-txt text-align-right">{{ColorBarES_Count}}</div>
            </div>
            <div class="row padding-2">
                <div class="col-md-8 color-desc-wrap ">
                    <span  class="indicator color-2"> </span>
                    <span class="code-txt">Optum Chart Review</span>
                </div>
                <div class="col-md-4 code-txt text-align-right">{{ColorBarCR_Count}}</div>
            </div>
            <div class="row padding-2">
                <div class="col-md-8 color-desc-wrap">
                    <span  class="indicator color-3"> </span>
                    <span class="code-txt"> Optum IOA</span>
                </div>
               <div class="col-md-4 code-txt text-align-right">{{ColorBarIOA_Count}}</div>
            </div>
            <div class="row padding-2">
                <div class="col-md-8 color-desc-wrap">
                    <span  class="indicator color-4"> </span>
                    <span class="code-txt"> Others</span>
                </div>
               <div class="col-md-4 code-txt text-align-right">{{ColorBarOTH_Count}}</div>
            </div>
        </div>
    </div> 
    <div class="col-md-6  error-tab-stats padding-left-3" >
        <div class="count-title">Errors Preventing Unlinked Submission</div>
        <div class="count-sub-title-wraper"> 
            <ng-container *ngFor="let item of agg?.totalErrorByTypeInfo">   
                <div class="count-value" *ngIf='item?.typeName=="PUS"'>{{item?.totalErrTypeCnt}}</div>
                </ng-container>
            <div class="count-label">Chart Review Records</div>
         </div>
         <div class="row padding-2 margin-top-5">
            <div class="col-md-8 code-txt bold">Top Error Codes</div>
            <div class="col-md-4 code-txt text-align-right bold">Qty</div>
        </div>
        <div class="border-top-title"></div>
        <ng-container *ngFor="let item of agg?.codeInfo">
            <div class="errors-table" *ngIf='item?.errType=="PUS"'>
                <div class="code-txt bold">{{item?.errCode}}</div>
                <div class="code-txt">{{item?.errCodeDescription}}</div>
                <div class="code-txt text-align-right">{{item?.errCodeQuantity}}</div>
            </div>
        </ng-container>
    </div>
</div>