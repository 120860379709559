<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3">
        <div><span class="chart-title">CMS Accepted Claims By Source</span>
            <span class="margin-left-15">
                <a href="#" (click)="exportData($event)">Export Data <uitk-icon-font icon="uimf-icon-export" hiddenText="export"></uitk-icon-font></a>
            </span>
        </div>
    </div>
    <div class="col-md-8 radio-group-wrapper">
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="chcbx_1"  [(ngModel)]="seriesNames.CmsExternalSupplemntal" name="chcbx_1" class="custom-control-input" (change)="reloadGraph()" checked="checked">
            <label class="custom-control-label red" for="chcbx_1">External Supplemental</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="chcbx_2"  [(ngModel)]="seriesNames.CmsOptumChartReview" name="chcbx_2" class="custom-control-input" (change)="reloadGraph()">
            <label class="custom-control-label red" for="chcbx_2">Optum Chart Review</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="chcbx_3"  [(ngModel)]="seriesNames.CmsOptumIOA" name="chcbx_2" class="custom-control-input" (change)="reloadGraph()">
            <label class="custom-control-label red" for="chcbx_3">Optum IOA</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="chcbx_4"  [(ngModel)]="seriesNames.CmsOthers" name="chcbx_2" class="custom-control-input" (change)="reloadGraph()">
            <label class="custom-control-label red" for="chcbx_4">Others</label>
        </div>
    </div>
</div>
<div class="margin-bottom-35 graph-wrapper">
    <line-chart [hidden]="isBarChartVissible()" [isHidden]="isBarChartVissible()"></line-chart>
    <app-bar-charts [scopeData]="data" [hidden]="!isBarChartVissible()" [isHidden]="!isBarChartVissible()">
    </app-bar-charts>
</div>
<div class="xlabel-wrapper">Encounter Date of Service</div>
<!-- <app-graph-switch-frequency [scopeProps]="data"></app-graph-switch-frequency> -->
<div class="row error-tab-stats-wrapper">
    <div class="col-md-3  error-tab-stats padding-left-3">
        <div class="count-title">External Supplemental</div>
        <div class="count-sub-title-wraper"> 
            <div class="count-value">{{aggData.externalSupplementalCnt}}</div>
            <div class="count-label">Claims</div>
         </div>
        <div class="border-top-title"></div>
        <div class="row padding-2">
            <div class="col-md-8 code-txt">Acceptance Rate</div>
            <div class="col-md-4 code-txt text-align-right">{{aggData.externalSupplementalPercent}} %</div>
        </div>
    </div>
    <div class="col-md-3  error-tab-stats padding-left-3">
        <div class="count-title">Optum Chart Review</div>
        <div class="count-sub-title-wraper"> 
            <div class="count-value">{{aggData.optumChartReviewCnt}}</div>
            <div class="count-label">Claims</div>
         </div>
        <div class="border-top-title"></div>
        <div class="row padding-2">
            <div class="col-md-8 code-txt">Acceptance Rate</div>
            <div class="col-md-4 code-txt text-align-right">{{aggData.optumChartReviewPercent}} %</div>
        </div>
    </div> 
    <div class="col-md-3  error-tab-stats padding-left-3">
        <div class="count-title">Optum IOA</div>
        <div class="count-sub-title-wraper"> 
            <div class="count-value">{{aggData.optumIOACnt}}</div>
            <div class="count-label">Claims</div>
         </div>
        <div class="border-top-title"></div>
        <div class="row padding-2">
            <div class="col-md-8 code-txt">Acceptance Rate</div>
            <div class="col-md-4 code-txt text-align-right">{{aggData.optumIOAPercent}} %</div>
        </div>
    </div>
    <div class="col-md-3  error-tab-stats padding-left-3">    
        <div class="count-title">Others</div>
        <div class="count-sub-title-wraper"> 
            <div class="count-value">0</div>
            <div class="count-label">Claims</div>
         </div>
        <div class="border-top-title"></div>
        <div class="row padding-2">
            <div class="col-md-8 code-txt">Acceptance Rate</div>
            <div class="col-md-4 code-txt text-align-right">0%</div>
        </div>
    </div>    
</div>