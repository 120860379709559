<div class="title">Closed Chart Review Records</div>
<div class="row closed-tab-stats-wrapper">
    <div class="col-md-4  closed-tab-stats padding-left-3" *ngFor="let agg of aggData">
        <div class="count-title">{{agg.tabTitle}}</div>
        <div class="count-sub-title-wraper"> 
            <div class="count-value">{{agg.count}}</div>
            <div class="count-label">Chart Review Records</div>
         </div>
        <div class="row border-top-title"></div>
        <div class="row padding-2">
            <div class="col-md-8 code-txt">External Supplemental</div>
            <div class="col-md-4 code-txt text-align-right">{{agg.externalSupplemental}}</div>
            <div class="col-md-8 code-txt">Optum Chart Review</div>
            <div class="col-md-4 code-txt text-align-right">{{agg.optumChartReview}}</div>
            <div class="col-md-8 code-txt">Optum IOA</div>
            <div class="col-md-4 code-txt text-align-right">{{agg.optumIOA}}</div>
            <div class="col-md-8 code-txt">Others</div>
            <div class="col-md-4 code-txt text-align-right">0</div>
        </div>
    </div>
</div>