<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3 width-left-3">
        <div><span class="chart-title">Chart Review Record Aging</span></div>
    </div>
</div>
<div class="row ">
    <div class="col-md-2 status-col "><span class="inprogress-hdr">Status</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">Total</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">0-6 Days</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">7-14 Days</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">15-30 Days</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">30+ Days</span></div>
</div>
<div class="border-top-title"></div>
<div class="row linepadding " *ngFor="let inprogress of agg;let i =index" [ngClass]="{'root-node': i%4 === 0}">
    <div *ngIf='inprogress?.status=="CRRReadyToLinkTotalCount"' class="col-md-2 status-col">
        <span class="text">Chart Review Records Ready to Link</span>
    </div>
    <div *ngIf='inprogress?.status=="CRRPendClaimCrtTotalCount"' class="col-md-2 status-col">
        <span class="text">Chart Review Records Pending Claim Creation</span>
    </div>
    <div *ngIf='inprogress?.status=="CRRReadyToLinkESCount" || inprogress?.status=="CRRPendClaimCrtESCount"' class="col-md-2 status-col">
        <span class="text">External Supplemental</span>
    </div>
    <div *ngIf='inprogress?.status=="CRRReadyToLinkOCRCount" || inprogress?.status=="CRRPendClaimCrtOCRCount"' class="col-md-2 status-col">
        <span class="text">Optum Chart Review</span>
    </div>
    <div *ngIf='inprogress?.status=="CRRReadyToLinkIOACount" || inprogress?.status=="CRRPendClaimCrtIOACount"' class="col-md-2 status-col">
        <span class="text">Optum IOA</span>
    </div>
    <div *ngIf='inprogress?.status=="CRRReadyToLinkOthersCount" || inprogress?.status=="CRRPendClaimCrtOthersCount"' class="col-md-2 status-col">
        <span class="text">Others</span>
    </div>
    <div class="col-md-2 right-align">
        <span  class="text">{{inprogress?.totalCount != '' ? inprogress?.totalCount : "-"}}</span>
    </div>      
    <div class="col-md-2 right-align">
        <span class="text">{{inprogress?.catgOneCount != '' ? inprogress?.catgOneCount : "-"}}</span>
    </div>      
    <div class="col-md-2 right-align">
        <span class="text">{{inprogress?.catgTwoCount != '' ? inprogress?.catgTwoCount : "-"}}</span>
    </div>
    <div class="col-md-2 right-align">
        <span class="text">{{inprogress?.catgThreeCount != '' ? inprogress?.catgThreeCount : "-"}}</span>
    </div>
    <div class="col-md-2 right-align">
        <span class="text">{{inprogress?.catgFourCount != '' ? inprogress?.catgFourCount : "-"}}</span>
    </div>
</div>
