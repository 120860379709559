export enum Constants {
  currentUser = 'currentUser',
  clientName = 'clientName',
  timePeriod = 'timePeriod',
  lastWeek = 'Last Week',
  lastMonth = 'Last Month',
  lastQuarter='Last Quarter',
  lastSixMonths='Last 6 Months',
  lastYear = 'Last Year',
  lastDay = 'Last Day',
  OpenSubmissionPeriod='Open Submission Period',
  dateRange = 'Date Range',
  today = 'Today',
  mmddyyyy = 'MM/dd/yyyy',
  summaryRecord = 'summaryRecord',
  processStatus = 'processStatus',
  mmddyyhhmmss = 'MMddyyhhmmss',
  fileName = 'fileName',
  inboundFileName = 'inboundFileName',
  fileKey = 'fileKey',
  fileReceivedDateKey = "fileReceivedDate",
  claimLoadDateLabel = 'Claim Load Date',
  claimLoadDateValue='claimLoadDt',
  claimServiceDateLabel='Claim Service Date',
  claimServiceDateValue='claimServiceDt',
  lastMonthTillDate='Last month till date',
  last3MonthsTillDate='Last 3 months till date',
  last6MonthsTillDate='Last 6 months till date',
  last12MonthsTillDate='Last 12 months till date',
  dateCriteria = 'Date Criteria',
  errorClient = 'Error Client',
  partySk = 'Party SK',
  includeChildData = 'includeChildData',
  respCtgCriteriaId = 'respCtgCriteriaId',
  clmDtCriteriaId = 'clmDtCriteriaId',
  timeCriteriaId = 'timeCriteriaId',
  dtStartMonth = 'dtStartMonth',
  dtStartYear = 'dtStartYear',
  dtEndMonth = 'dtEndMonth',
  dtEndYear = 'dtEndYear',
  timePeriodValue = 'timePeriodValue',
  errorCode='errorCode',
  errorCategory = 'errorCategory',
  errorLocation = 'errorLocation',
  fromDate = 'fromDate',
  toDate = 'toDate',
  duration = 'duration',
  customDateRange= 'Custom Date Range',
  monthly= 'monthly',
  daily='daily',
  isSingleMonthDateRange = "isSingleMonthDateRange",
  graphViewByType = 'graphViewByType',
  fileReceivedDate='File Received Date',
  viewingBy = 'Viewing By',
  recordType = "recordType",
  claimDosDate = 'Claim Date Of Service',
  yAxisLabel = 'yAxisLabel',
  seriesName = 'seriesName',
  tabsSelectedIndex = "tabsSelectedIndex",
  filesRecieved = 'Files Received',
  filesCompleted = 'Files Completed',
  graphSeriesId ='graphSeriesId',
  inboundCompliance = 'Inbound Compliance',
  inboundContent = 'Inbound Content',
  cms999 = 'CMS 999',
  cms277='CMS 277',
  cmsMAO_002='CMS MAO-002',
  Institutional='Institutional',
  Professional='Professional',
  Replaced='Replaced',
  PerClientRequest='Per Client Request',
  inboundDupClaim ='Inbound Duplicate Claims',
  cmsDupClaim ='CMS Duplicate Claims',
  claimsRecieved = 'Claims Recieved',
  inboundErrorScreen = 'inbound',
  cmsErrorScreen = "cms",
  mdeScreen = 'mde',
  userPrincipal = "userPrincipal",
  displayResults = "Display Results",
  Cancel = "cancel",
  exportResults = "Export Results",
  refineFilters = "Refine Filters",
  maxRowLimit = "70000",
  resetFilter = "reset",
  errorType = "errorType",
  screenType = "screenType",
  fileLoadScreenType = "S1",
  inboundErrorScreenType = "S2",
  fileLoadExportScreenCode = "FLA",
  cmsErrorScreenType = "S3",
  errorByTypeScreenType = "S4",
  errorDetailsScreenType = "S5",
  mdeScreenType = "S6",
  defaultScreenType = "S0",
  duplicateClaimsScreenType = "S7",
  closedClaimsScreenType = "S8",
  maxRecordsPerSeg = "1000",
  viewByDate = "viewByDate",
  errorsInClaims = "Errors In Claims",
  loop="loop",
  mdeFilter = "mdeFilter",
  scopeDataSessionKeys = "scopeDataSessionKeys",
  mdeResubmittedStatus = "IN_PROGRESS",
  mdeResubmittedKey = "correctionClmGenStatus",
  header= "HEADER",
  errorCatgService= "SERVICE",
  errorCatgProvider= "PROVIDER",
  diagnosisCodeAccordion = "DIAGNOSIS_CD",
  diagnosisCodeAccordionUIRef = "DIAG_QUALIFIER_CD",
  additionalHltCareAccordion = "ADDNL_DIAGNOSIS_CD",
  additionalClaimDatesAccordion = "ADDNL_CLAIM_DATES",
  additionalClaimDatesAccordionUIRef = "DT_FMT_QUALIFIER",
  additionalClaimDateTimeUIRef = "DTTM_FMT_QUALIFIER",
  claimReferenceAccordion = "CLAIM_REF_INFO",
  claimReferenceAccordionUIRef = "REF_ID",
  addlnSVCRefInfo = "ADDL_SRVC_REF_INFO",
  claimAdjAccordion="CLAIM_ADJ",
  claimAdjAccordionUIRef="ADJ_GRP_CD",
  errorCorrectedStatus = "EDITED",
  mdeResubmittedFlow = "mdeSubmitted",
  errorCorrectedFlow = "errCorrected",
  errorSubmittedFlow = "submittedFlow",
  claimClosedFlow = "closedFlow",
  claimLockedFlow = "lockedFlow",
  errorSubmittedStatus ="SUBMITTED",
  claimClosedStatus ="CLOSED",
  poaUISec ="DIAG_CODES",
  poaUIRef = "POA",
  entityTypeRefCol = "ENTITY_TYPE_CODE",
  entityIdUIRef = "ENTITY_ID",
  billingContactUIRef = "BILLING_CONTACT_INFO",
  billingContactUISec = "BILLING_PROVIDER",
  entityIdUISec = "SERVICE_LINE_PROVIDERS",
  providerSec="PROVIDER_TYPE",
  scvLineSec = "SERVICE_LINE",
  unitMeasureRef= "UNIT_MEASUREMENT_CODE",
  ndcUnitMeasureRef= "NDC_UNIT_MEASURE",
  procedureCodeQual="PROC_CD_QUALIFER",
  addlnSVCDatesCol = "ADDL_SRVC_DATES",
  closedClaimMsg='It is no longer available for editing or resubmission',
  claimLocked = "Y",
  claimUnlocked = "N",
  member = "MEMBER",
  memberUISec ="MEMBER_INFO",
  memberRefCol = "GENDER",
  clmId = "clmId",
  generatedStatus = "GENERATED",
  failedStatus = "FAILED",
  spoltUrl = "spoltUrl",
  reportingServiceBaseUrlSuffix = "/reporting",
  reportingServiceBaseCRRUrlSuffix = "/reporting-asm",
  exportServiceBaseUrlSuffix = "/export",
  correctionServiceBaseUrlSuffix = "/correction",
  headerAdjustmentSec = "Claim Adjustments",
  ServiceAdjustmentSec = "Adjustment Records",
  diagCodeSec = "Diagnosis Codes",
  addHealthSec = "Additional Health Care Information Codes",
  export="exportResult", 
  loadMore="loadMore",
  singleClaimLockedDesc = "The Claim has been locked for editing by another user.",
  sessionLockedRecords = "sessionLockedRecords",
  MDEClosed = "MDEClosed",
  someClmIdNotAvlblConst = "Some claim IDs are not available",
  alert = "Alert",
  sessionTimeoutInMiliseconds = '1200000',
  timerInterval = '120000',
  milliSecondsInASecond = '1000',
  minutesInAnHour = '60',
  secondsInAMinute  = '60',
  maintenanceServiceBaseUrlSuffix = "/maintenance",
  ClaimRecordType = "Claim Records",
  ChartReviewRecordType = "Chart Review Records",
  inprogressScreenType = 'S9',
  cmsAcceptedScreenType = 'S10',
  inprogressClaimStatus = 'inprogressClaimStatus',
  claimAge = 'claimAge',
  ExternalSupplemental = "External Supplemental",
  OptumChartReview = "Optum Chart Review",
  OptumIOA = "Optum IOA",
  Others = "Others",
  CmsExternalSupplemntal = 'CmsExternalSupplemntal',
  CmsOptumChartReview = 'CmsOptumChartReview',
  CmsOptumIOA = 'CmsOptumIOA',
  CmsOthers = 'CmsOthers'
}
