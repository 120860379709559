<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3">
        <div><span class="chart-title">Chart Review Record Activity</span></div>
    </div>
    <div class="col-md-8 radio-group-wrapper">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_1" name="rd" class="custom-control-input"
                (change)="reloadGraph('External Supplemental')" checked="checked">
            <label class="custom-control-label red" for="rd_1">External Supplemental</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_2" name="rd" class="custom-control-input"
                (change)="reloadGraph('Optum Chart Review')">
            <label class="custom-control-label red" for="rd_2">Optum Chart Review</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_3" name="rd" class="custom-control-input" (change)="reloadGraph('Optum IOA')">
            <label class="custom-control-label red" for="rd_3">Optum IOA</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_4" name="rd" class="custom-control-input" (change)="reloadGraph('Others')">
            <label class="custom-control-label red" for="rd_4">Others</label>
        </div>
    </div>
</div>
<div class="margin-bottom-35 graph-wrapper">
    <line-chart [hidden]="isBarChartVissible()" [isHidden]="isBarChartVissible()"></line-chart>
    <app-bar-charts [scopeData]="data" [hidden]="!isBarChartVissible()" [isHidden]="!isBarChartVissible()">
    </app-bar-charts>
</div>
<div class="xlabel-wrapper">Encounter Date of Service</div>
<!-- <app-graph-switch-frequency [scopeProps]="data"></app-graph-switch-frequency> -->
<div class="row summary-tab-stats-wrapper">
    <div class="col-md-3  summary-tab-stats padding-left-3" >
        <div class="count-title">External Supplemental</div>
        <div class="count-sub-title-wraper">
            <div class="count-value">{{countData[0].externalSupplementalCnt}}</div>
            <div class="count-label">Chart Review Records</div>
        </div>
    </div>
    <div class="col-md-3  summary-tab-stats padding-left-3" >
        <div class="count-title">Optum Chart Review</div>
        <div class="count-sub-title-wraper">
            <div class="count-value">{{countData[0].optumChartReviewCnt}}</div>
            <div class="count-label">Chart Review Records</div>
        </div>
    </div>
    <div class="col-md-3  summary-tab-stats padding-left-3" >
        <div class="count-title">Optum IOA</div>
        <div class="count-sub-title-wraper">
            <div class="count-value">{{countData[0].optumIOACnt}}</div>
            <div class="count-label">Chart Review Records</div>
        </div>
    </div>
    <div class="col-md-3  summary-tab-stats padding-left-3" >
        <div class="count-title">Others</div>
        <div class="count-sub-title-wraper">
            <div class="count-value">0</div>
            <div class="count-label">Chart Review Records</div>
        </div>
    </div>
</div>