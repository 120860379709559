import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OauthResponse } from '../auth/oauthResponse.model';
import { environment } from 'src/environments/environment';
import { LineChart } from '../../data-models/line.chart';
import {  map } from 'rxjs/operators';
import { Constants } from 'src/app/data-models/constants.enum';
import { of } from 'rxjs';
import { CRRSummary, ClosedTabs, CreationTabs, CRRChartData, CRRErrorStats, CRRAcceptedTab, CRR_Record_Error, CRRInprogress, CRRClaimInprogress } from '../../charts/mockdata';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  private  baseServerUrl = environment.baseServerUrl;
  private  baseServerCRRUrl = environment.baseServerUrl;

  constructor(private readonly http: HttpClient) { }

  getBaseServerUrl(){
    const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
    if(resp.stargateEnabled){
        this.baseServerUrl = environment.starGateUrl+Constants.reportingServiceBaseUrlSuffix;
        this.baseServerCRRUrl = environment.starGateUrl+Constants.reportingServiceBaseCRRUrlSuffix;
    }
    else{
        this.baseServerUrl = environment.backedBaseUrl+Constants.reportingServiceBaseUrlSuffix;
    }
  }
  getSummaryStats(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate){
    console.log("getSummaryStats ",environment.baseServerUrl, partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate);
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/tab-infos`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate});
  }

  getCmsAcceptedSummaryStats(partySK,isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId){
    console.log("getCmsAcceptedSummaryStats ",environment.baseServerUrl, partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId);
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/accepted-details`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId});
  }

  /* This service call is used to get duplicate claims count on the basis of tabId*/
  getDuplicateSummaryStats(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/duplicate-details`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId});
  }
  
  getClosedSummaryStats(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId){
    console.log("inside Summary stats ",environment.baseServerUrl, partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId);
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/closed-details`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId});
  }
  getChartReviewRecordCount(partySK, isChildDataIncluded,year_val,startDate,endDate,tabId){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerCRRUrl}/crr-summary-results`,{partySK, isChildDataIncluded, year_val,startDate,endDate,tabId});
   }

  getCRRErrorTabStats(partySK, isChildDataIncluded,year_val,startDate,endDate,tabId){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerCRRUrl}/crr-claimerrors-results`,{partySK, isChildDataIncluded, year_val,startDate,endDate,tabId});
   }
   getCrrAcceptedTabStats(partySK, isChildDataIncluded, year_val,startDate,endDate,tabId){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerCRRUrl}/crr-cms-accepted-results`,{partySK, isChildDataIncluded, year_val,startDate,endDate,tabId});
   }

  getChartStats(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,year_val,startDate,endDate,graphSeriesId,{duration,graphViewByType}){
    if( graphSeriesId && graphSeriesId.length > 0 && graphSeriesId[0] > 10){
      this.getBaseServerUrl();
      //graphSeriesId[0] = graphSeriesId[0] - 11;
      graphSeriesId = graphSeriesId.map(id => id > 10 ? id - 11 : id);
      
      return this.http.post<any>(`${this.baseServerCRRUrl}/crr-charts`,{partySK, isChildDataIncluded,year_val,startDate,endDate,graphSeriesId,duration,graphViewByType});
    }
    this.getBaseServerUrl();
    return this.http.post<any>(`${this.baseServerUrl}/v1/charts`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,graphSeriesId,duration,graphViewByType});
  }

  getCompletedFilesCount(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId){
    console.log("getCompletedFilesCount ",environment.baseServerUrl, partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId);
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/aggregate-details`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId});
  }

  getSummaryGraphStats(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,seriesName,{duration, graphViewByType}){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/summary-graph-data`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,seriesName,duration,graphViewByType});
  }

  getErrorsTabStats(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/top5error-details`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId});
  }

  getTimePeriod(clmDtCriteriaId){
    this.getBaseServerUrl();
    
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/timePeriods`,{clmDtCriteriaId}).pipe(map((data:any[])=>{
     //  US827569 : added check for removing year 2019 from timeperiod response
     return data.filter((item)=>{
        return clmDtCriteriaId===0 ||  item.value !== '2019'  
      })
  
    }));
  }

  getInProgessDetails(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/inProgress-details`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId});
  }

  getFileLoaData(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,pageCriteria, filterSortSelection){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/FileStatus`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,pageCriteria, ...filterSortSelection});
  }

  getFileLoadRecordsCount(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,pageCriteria,filterSortSelection){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/FileStatusCounts`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,pageCriteria, ...filterSortSelection});
  }

  getPrioritizeOptionsForFileLoad(screenType){
    this.getBaseServerUrl();
    const pageInfo = {
      pageInfo: screenType
    }
    return this.http.post<any[]>(`${this.baseServerUrl}/prioritize-options`,pageInfo);
  }

  getLoadComplete(){
    this.getBaseServerUrl();
    return this.http.get<any[]>(`${this.baseServerUrl}/loadcomplete-period`);
  }

  getClaimAge() {
    this.getBaseServerUrl();
    return this.http.get<any[]>(`${this.baseServerUrl}/v1/claimAge`);
}

getClaimStatus() {
    this.getBaseServerUrl();
    return this.http.get<any[]>(`${this.baseServerUrl}/v1/claimStatus`);
}

  getCRRSummaryStats(partySK, isChildDataIncluded,year_val,startDate,endDate){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerCRRUrl}/crr-home-results`,{partySK, isChildDataIncluded,year_val,startDate,endDate});
  }
  
  getCrrClosedTabStats(partySK, isChildDataIncluded,year_val,startDate,endDate){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerCRRUrl}/crr-closed-results`,{partySK, isChildDataIncluded,year_val,startDate,endDate});
  }
  getCrrErrorRecordsTabStats(partySK, isChildDataIncluded,year_val,startDate,endDate){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerCRRUrl}/crr-errors/errors-result`,{partySK, isChildDataIncluded,year_val,startDate,endDate});
  }
  getCrrClaimCreationTabStats(partySK, isChildDataIncluded,year_val,startDate,endDate){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerCRRUrl}/crr-claimcreation-results`,{partySK, isChildDataIncluded,year_val,startDate,endDate});
  }
  getCRRInProgessDetails(partySK, isChildDataIncluded,year_val,startDate,endDate){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerCRRUrl}/crr-inprogress-results`,{partySK, isChildDataIncluded,year_val,startDate,endDate});
  }
  getCRRClaimInProgessDetails(partySK, isChildDataIncluded,year_val,startDate,endDate){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerCRRUrl}/crr-claiminprogress-results`,{partySK, isChildDataIncluded,year_val,startDate,endDate});
  }
}


