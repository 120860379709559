<div class="title-wrap"> 
    <span class="title">Claims Created From Chart Review Records</span>
    <span class="margin-left-15">
        <a href="#" (click)="exportData($event)">Export Data <uitk-icon-font icon="uimf-icon-export" hiddenText="export"></uitk-icon-font></a>
    </span>
</div>
<div class="row claim-creation-tab-stats-wrapper">
    <div class="col-md-3  claim-creation-tab-stats padding-left-3" *ngFor="let item of agg">
        <div class="count-title">{{item.tabTitle}}</div>
        <div class="count-sub-title-wraper"> 
            <div class="count-value">{{item.count}}</div>
            <div class="count-label">{{item.headerText}}</div>
         </div>
        <div class="row border-top-title"></div>
        <div class="row padding-2">
            <div class="col-md-8 code-txt">External Supplemental</div>
            <div class="col-md-4 code-txt text-align-right">{{item.externalSupplemental}}</div>
            <div class="col-md-8 code-txt">Optum Chart Review</div>
            <div class="col-md-4 code-txt text-align-right">{{item.optumChartReview}}</div>
            <div class="col-md-8 code-txt">Optum IOA</div>
            <div class="col-md-4 code-txt text-align-right">{{item.optumIOA}}</div>
            <div class="col-md-8 code-txt">Others</div>
            <div class="col-md-4 code-txt text-align-right">0</div>
        </div>
    </div>
</div>