import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { BarChartsComponent } from '../../charts/bar-charts/bar-charts.component';
import { SummaryService } from '../../services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../data-models/constants.enum';
import { LineChartComponent } from '../../charts/lineCharts/lineCharts.component';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-crr-cms-accepted',
  templateUrl: './crr-cms-accepted.component.html',
  styleUrls: ['./crr-cms-accepted.component.css']
})

export class CrrCmsAcceptedComponent implements OnInit {

  @Input()
  public data: any;

  @ViewChild(LineChartComponent, { static: false })
  public lineChart: LineChartComponent;

  @ViewChild(BarChartsComponent, { static: false })
  public barChart: BarChartsComponent;

  public graphSeriesId: number[] = [];

  aggData: any;

  seriesNames = {
    CmsExternalSupplemntal: true,
    CmsOptumChartReview: false,
    CmsOptumIOA: false,
    CmsOthers: false
  };

  selectedSeriesNames: string[] = [];

  constructor(private summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService) { }

  ngOnInit() {
    const partySk = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName).value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const year_val = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod).value;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.sessionStorage.set(Constants.yAxisLabel, JSON.stringify("Accepted Claims"));
    this.sessionStorage.set(Constants.seriesName, JSON.stringify("External Supplemental"));
    this.graphSeriesId = [17];
    this.sessionStorage.set(Constants.graphSeriesId, this.graphSeriesId);
    this.sessionStorage.set(Constants.tabsSelectedIndex, 6);

    this.summaryService.getCrrAcceptedTabStats(partySk, isChildDataIncluded,year_val,startDate,endDate,0).subscribe((data: any) => {
      this.aggData = data[0];
    });
    this.data["frequencyChange"] = this.reloadGraph.bind(this);
  }

  isBarChartVissible() {
    return false;
  }

  reloadGraph() {
    this.selectedSeriesNames = Object.keys(this.seriesNames).filter(seriesName => this.seriesNames[seriesName]);
    this.sessionStorage.set(Constants.seriesName, JSON.stringify(this.selectedSeriesNames));
    this.graphSeriesId = []; // to clear previous selections
    this.selectedSeriesNames.forEach(seriesName => {
      if (Constants.CmsExternalSupplemntal === seriesName) {
        this.graphSeriesId.push(17);
      } else if (Constants.CmsOptumChartReview === seriesName) {
        this.graphSeriesId.push(18);
      } else if (Constants.CmsOptumIOA === seriesName) {
        this.graphSeriesId.push(19);
      }
        //  else if (Constants.CmsOthers === seriesName) {
      //   this.graphSeriesId.push(21);
      // }    //uncomment when the other series needs to be implemented 
    });

    if (this.isBarChartVissible()) {
      this.barChart.reloadGraph(this.graphSeriesId);
    } else {
      this.lineChart.reloadGraph(this.graphSeriesId);
    }
  }

  exportData(event){
    event.preventDefault();
  }

}

