import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { SummaryService } from 'src/app/services/tabs/summary.service';
import { Constants } from 'src/app/data-models/constants.enum';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-crr-inprogress',
  templateUrl: './crr-inprogress.component.html',
  styleUrls: ['./crr-inprogress.component.css']
})
export class CrrInprogressComponent implements OnInit {
  @Input()
  public data: any;

  public dummyData = [
    {
        "status": "CRRReadyToLinkTotalCount",
        "totalCount": "70",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "70",
        "catgFourCount": "0"
    },
    {
        "status": "CRRReadyToLinkESCount",
        "totalCount": "43",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "43",
        "catgFourCount": "0"
    },
    {
        "status": "CRRReadyToLinkOCRCount",
        "totalCount": "7",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "7",
        "catgFourCount": "0"
    },
    {
        "status": "CRRReadyToLinkIOACount",
        "totalCount": "5",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "5",
        "catgFourCount": "0"
    },
	{
        "status": "CRRReadyToLinkOthersCount",
        "totalCount": "15",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "15",
        "catgFourCount": "0"
    },
    {
        "status": "CRRPendClaimCrtTotalCount",
        "totalCount": "0",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "0",
        "catgFourCount": "0"
    },
    {
        "status": "CRRPendClaimCrtESCount",
        "totalCount": "0",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "0",
        "catgFourCount": "0"
    },
    {
        "status": "CRRPendClaimCrtOCRCount",
        "totalCount": "0",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "0",
        "catgFourCount": "0"
    },
    {
        "status": "CRRPendClaimCrtIOACount",
        "totalCount": "0",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "0",
        "catgFourCount": "0"
    },
	{
        "status": "CRRPendClaimCrtOthersCount",
        "totalCount": "0",
        "catgOneCount": "0",
        "catgTwoCount": "0",
        "catgThreeCount": "0",
        "catgFourCount": "0"
    }
]
  
  agg:any;

  constructor(@Inject(SESSION_STORAGE) 
  private readonly sessionStorage: WebStorageService,
  private readonly summaryService: SummaryService) { }

  ngOnInit() {
    const partySk = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const year_val = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.value;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.summaryService.getCRRInProgessDetails(partySk,isChildDataIncluded,year_val,startDate,endDate).subscribe(data => {
      this.agg = data;
    });
  }

}
